<template>
  <a-modal :destroy-onClose="true" :title="title" :visible="visible" :confirm-loading="confirmLoading"
           :maskClosable="false" @ok="handleOk" @cancel="handleCancel" :width="800" v-drag-modal>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" v-if="visible">
      <a-form-model-item
          label="类型"
          prop="itemType"
          v-bind="formItemLayout"
      >
        <a-input v-model="form.itemType" v-decorator="['itemType']" placeholder="类型" disabled :max-length="30">
        </a-input>
      </a-form-model-item>
      <a-form-model-item
          label="名称"
          prop="itemName"
          v-bind="formItemLayout"
      >
        <a-input v-model="form.itemName" v-decorator="['itemName']" placeholder="显示名称">
        </a-input>
      </a-form-model-item>
      <a-form-model-item
          v-for="(item, index) in items"
          :key="item.key"
          v-bind="formItemLayout"
          :label="item.label"
          :prop="item.key"
      >
        <a-input
            v-model="item.value"
            placeholder="输入具体值"
            style="width: 60%; margin-right: 8px"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>


<script>

export default {
  name: "RewordBriefForm",
  data() {
    return {
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 16},
      },
      visible: false,
      confirmLoading: false,
      title: "修改财务项目",
      textMode: "",
      form: {
        itemType: "",
        itemName: ""
      },
      items: [],
      rules: {
        itemName: [
          {required: true, message: "请输入名称", trigger: "blur"},
        ],
      },
    };
  },

  methods: {
    handleCancel(e) {
      this.visible = false;
      this.form = {};
    },
    async showModal(record) {
      this.visible = true;
      this.title = "修改财务项目";
      this.form = {
        ...record
      };
      let items = JSON.parse(record.itemDetail);
      this.items = Object.keys(items).map(k => {
        return {
          key: k,
          label: this.$helper.getDictName(k, "rewardBriefItems"),
          value: items[k]
        }
      })
    },

    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        // console.log(this.form);
        // return

        if (valid) {
          this.confirmLoading = true;
          let json = {};
          this.items.forEach(v => {
            json[v.key] = v.value
          });
          this.form.itemDetail = JSON.stringify(json)
          console.log(this.form)

          this.$postJson("rewardBrief/update", this.form)
              .then((res) => {
                this.confirmLoading = false;
                this.visible = false;
                this.$message.success("操作成功！");
                this.$emit("success", res.data);
              })
              .catch(() => {
                this.confirmLoading = false;
              });
        } else {
          // console.log(this);
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scope>
</style>
